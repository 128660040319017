import { createStore, combineReducers, compose, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'

import reducers from '../reducers'

const composeEnhancers =
  (typeof window !== 'undefined' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose

export default function configureStore(initialState) {
  return createStore(
    combineReducers({
      ...reducers,
    }),
    initialState,
    composeEnhancers(applyMiddleware(thunk)),
  )
}
